// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-tags-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-author-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/home/travis/build/ms314006/gatsby-profile/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */)
}


module.exports = [{
      plugin: require('/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1170,"quality":90},
    },{
      plugin: require('/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ms314006.github.io"},
    },{
      plugin: require('/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154480488-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"sampleRate":100,"siteSpeedSampleRate":10},
    },{
      plugin: require('/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clark's World","short_name":"Clark's World","start_url":"/","background_color":"#f7f0eb","theme_color":"#000000","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('/home/travis/build/ms314006/gatsby-profile/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
